<footer id="footer" role="contentinfo" class="footer pb-0" [ngStyle]="{'background-color': envtemplate.footerbackgroundcolor}" >
	<a class="spinning btn form-control" (click)="showoffers()" data-toggle="tooltip" data-placement="top" title="Ver las mejores ofertas"></a>
	<div class="container">
		<div class="row">
			<div class="col-12 col-lg-6">
				<img src="{{ envtemplate.principallogo }}" width="{{envtemplate.footerlogowidth}}" alt="logotipo isiix">
				<p class="mt-4 pt-2" style="text-align: justify; line-height:15px; font-size:13px;"><b style="color: #060443;">ISIIX, INTELIGENCIA EN SEGUROS</b> estamos comprometidos en protegerte a ti, a tus seres queridos y tus bienes. Como parte de esta protección, te informamos que los datos personales que nos proporciones o se obtengan, son tratados con absoluta confidencialidad y que contamos con las medidas de seguridad suficientes para garantizar su protección en apego a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>
			</div>
			<div class="col-12 col-lg-6 mt-5 pt-5 text-right">
				<ul class="list-inline mt-3 pt-3 text-dark">
					<li class="list-inline-item mr-3"><a href="" class="text-dark">Términos y condiciones</a></li>
					<li class="list-inline-item mr-3"><a href="" class="text-dark">Aviso de privacidad</a></li>
					<li class="list-inline-item"><a href="" class="text-dark">Contacto</a></li>
				</ul>

			</div>
		</div>
	</div>
	<div class="container-fluid mt-3" [ngStyle]="{'background-color': envtemplate.footerbackgroundcolor}">
		<div class="container">


		<div class="row">
			<div class="col-12 col-lg-12 pt-4 pb-3" >
				<div id="copyright" style="font-family:'Montserrat', sans-serif; font-size: 15px; font-weight: 400;">
					© 2021 Copyright | ISIIX  INTELIGENCIA EN SEGUROS INSTITUCIONALES E INDIVIDUALES
				</div>
			</div>
</div>
		</div>

	</div>
	<div id="ModalPromotions" class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg" style="max-width: 800px;">
			<div class="modal-content" style="overflow-x: scroll;">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Mira las ofertas que podrías perderte</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" style="height: 400px;">
		<style>
		.containerpromo:hover{
		font-style: italic;


		}
		</style>
			<div class="table" style="border: none;">
				<div class="col-md-12" style="border: none;">
				<div class="row" style="border: none;">
					<div class="col-md-3 containerpromo" style="cursor: pointer;/* border-style: solid; */border: lightgray solid 0.1px;border-radius: 5px;padding: 5px;height: 110px;z-index: 0;min-width: 350px;text-align:left !important">             <div style="background-color: #fafdff;cursor: pointer;width: 210px;height: 0px;border-right: 60px solid transparent;border-top: 100px solid #1f4991;position: absolute;">             </div>             <label class="trapecio-text" style="position: absolute;color: white;font-size: 7pt;margin: 10px 10px;cursor: pointer;">Contrata tu seguro de auto con: </label>             <label class="trapecio-text" style="position: absolute;color: white;font-size: 35pt;margin: 10px 10px;font-weight: 500;cursor: pointer;">MSI </label><label class="trapecio-text" style="position: absolute;color: white;font-size: 15pt;margin: 20px 110px;cursor: pointer;"></label><label class="trapecio-text" style="position: absolute;color: white;font-size: 15pt;margin: 40px 110px;cursor: pointer;">CON </label><label class="trapecio-text" style="position: absolute;color: white;font-size: 8pt;margin: 70px 10px;font-weight: 500;cursor: pointer;">Tarjetas participantes</label>           <img class="logo-quote img-fluid ng-tns-c199-4" src="assets/img/seguros/gnp_seguro_sq.png" style="position: absolute;width: 150px;margin: 15px 180px;cursor: pointer;"></div>
					<div class="col-md-3 containerpromo" style="cursor: pointer;/* border-style: solid; */border: lightgray solid 0.1px;border-radius: 5px;padding: 5px;height: 110px;z-index: 0;min-width: 350px;text-align:left !important">             <div style="background-color: #fafdff;cursor: pointer;width: 210px;height: 0px;border-right: 60px solid transparent;border-top: 100px solid #802b97;position: absolute;">             </div>             <label class="trapecio-text" style="position: absolute;color: white;font-size: 7pt;margin: 10px 10px;cursor: pointer;">Contrata tu seguro de auto con: </label>             <label class="trapecio-text" style="position: absolute;color: white;font-size: 35pt;margin: 10px 10px;font-weight: 500;cursor: pointer;">35% </label><label class="trapecio-text" style="position: absolute;color: white;font-size: 15pt;margin: 20px 110px;cursor: pointer;">DE</label><label class="trapecio-text" style="position: absolute;color: white;font-size: 15pt;margin: 40px 110px;cursor: pointer;">DTO </label><label class="trapecio-text" style="position: absolute;color: white;font-size: 8pt;margin: 70px 10px;font-weight: 500;cursor: pointer;">En Pago de Contado</label><label class="trapecio-text" style="position: absolute;color: white;font-size: 8pt;margin: 83px 10px;font-weight: 500;cursor: pointer;">+MSI</label>           <img class="logo-quote img-fluid ng-tns-c199-4" src="assets/img/seguros/qualitas_seguro_sq.png" style="position: absolute;width: 150px;margin: 15px 180px;cursor: pointer;"></div>         
					<div class="col-md-3 containerpromo" style="cursor: pointer;/* border-style: solid; */border: lightgray solid 0.1px;border-radius: 5px;padding: 5px;height: 110px;z-index: 0;min-width: 350px;text-align:left !important">             <div style="background-color: #fafdff;cursor: pointer;width: 210px;height: 0px;border-right: 60px solid transparent;border-top: 100px solid #dc1628;position: absolute;">             </div>             <label class="trapecio-text" style="position: absolute;color: white;font-size: 7pt;margin: 10px 10px;cursor: pointer;">Contrata tu seguro de auto con: </label>             <label class="trapecio-text" style="position: absolute;color: white;font-size: 35pt;margin: 10px 10px;font-weight: 500;cursor: pointer;">30% </label><label class="trapecio-text" style="position: absolute;color: white;font-size: 15pt;margin: 20px 110px;cursor: pointer;">DE</label><label class="trapecio-text" style="position: absolute;color: white;font-size: 15pt;margin: 40px 110px;cursor: pointer;">DTO </label><label class="trapecio-text" style="position: absolute;color: white;font-size: 30pt;margin: 50px 10px;font-weight: 500;cursor: pointer;">+MSI</label>           <img class="logo-quote img-fluid ng-tns-c199-4" src="assets/img/seguros/banorte_seguro_sq.png" style="position: absolute;width: 150px;margin: 15px 180px;cursor: pointer;"></div>                    
					<div class="col-md-3 containerpromo" style="cursor: pointer;/* border-style: solid; */border: lightgray solid 0.1px;border-radius: 5px;padding: 5px;height: 110px;z-index: 0;min-width: 350px;text-align:left !important">             <div style="background-color: #fafdff;cursor: pointer;width: 210px;height: 0px;border-right: 60px solid transparent;border-top: 100px solid #3372b9;position: absolute;">             </div>             <label class="trapecio-text" style="position: absolute;color: white;font-size: 7pt;margin: 10px 10px;cursor: pointer;">Contrata tu seguro de auto con: </label>             <label class="trapecio-text" style="position: absolute;color: white;font-size: 35pt;margin: 10px 10px;font-weight: 500;cursor: pointer;">10% </label><label class="trapecio-text" style="position: absolute;color: white;font-size: 15pt;margin: 20px 110px;cursor: pointer;">DE</label><label class="trapecio-text" style="position: absolute;color: white;font-size: 15pt;margin: 40px 110px;cursor: pointer;">DTO </label><label class="trapecio-text" style="position: absolute;color: white;font-size: 8pt;margin: 70px 10px;font-weight: 500;cursor: pointer;">En Pago de Contado</label>           <img class="logo-quote img-fluid ng-tns-c199-4" src="assets/img/seguros/general_seguro_sq.png" style="position: absolute;width: 150px;margin: 15px 180px;cursor: pointer;"></div>          
					<div class="col-md-3 containerpromo" style="cursor: pointer;/* border-style: solid; */border: lightgray solid 0.1px;border-radius: 5px;padding: 5px;height: 110px;z-index: 0;min-width: 350px;text-align:left !important">             <div style="background-color: #fafdff;cursor: pointer;width: 210px;height: 0px;border-right: 60px solid transparent;border-top: 100px solid #b30b2e;position: absolute;">             </div>             <label class="trapecio-text" style="position: absolute;color: white;font-size: 7pt;margin: 10px 10px;cursor: pointer;">Contrata tu seguro de auto con: </label>             <label class="trapecio-text" style="position: absolute;color: white;font-size: 35pt;margin: 10px 10px;font-weight: 500;cursor: pointer;">25% </label><label class="trapecio-text" style="position: absolute;color: white;font-size: 15pt;margin: 20px 110px;cursor: pointer;">DE</label><label class="trapecio-text" style="position: absolute;color: white;font-size: 15pt;margin: 40px 110px;cursor: pointer;">DTO </label><label class="trapecio-text" style="position: absolute;color: white;font-size: 30pt;margin: 50px 10px;font-weight: 500;cursor: pointer;">+MSI</label>           <img class="logo-quote img-fluid ng-tns-c199-4" src="assets/img/seguros/mapfre_seguro_sq.png" style="position: absolute;width: 150px;margin: 35px 185px;cursor: pointer;"></div> 
				</div>
				</div>
			</div>

			<div class="modal-footer">  
			</div>
			</div>
		</div>
		</div>
		</div>
</footer>
