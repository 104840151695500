import { PolizaRepository } from 'src/app/core/repositories/PolizaRepository';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PolizaDTO, GetPolizaDTO, PDFPolizaDTO, SendPolizaRequestDTO, SendPolizaDTO } from 'src/app/data/dto/PolizaDTO';
import { GetCotizacionDTO, PDFCotizacionDTO } from 'src/app/data/dto/PolizaDTO';

@Injectable({
    providedIn: 'root'
})
export class PolizaRestRepository implements PolizaRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    createPoliza(poliza: GetPolizaDTO): Observable<PolizaDTO> {
        const endpoint = environment.poliza_endpoint;
        const idCotizacion = poliza.idCotizacion;
        const idAseguradora = poliza.idAseguradora;
        const httpOptions = { headers: this.headers };

        console.log("createPoliza ==> ", poliza);

        let complete_url:any = '';
        let basic_url:any = `${environment.url_base}/${endpoint}/${idCotizacion}/${idAseguradora}`

        if (poliza.tt) {
          complete_url = basic_url + `?q=${encodeURIComponent(poliza.tt)}`
        } else {
          complete_url = basic_url;
        }

        console.log("complete_url ===> ", complete_url);

        return this.http.
            get<PolizaDTO>(complete_url, httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getPolizaPDF(file: GetPolizaDTO): Observable<PDFPolizaDTO> {
        const endpoint = environment.pdf_poliza_endpoint;
        const idCotizacion = file.idCotizacion;
        const idAseguradora = file.idAseguradora;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<PDFPolizaDTO>(`${environment.url_base}/${endpoint}/${idCotizacion}/${idAseguradora}`, httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getCotizacionPDFAgentes(file: GetCotizacionDTO): Observable<PDFCotizacionDTO> {
        const endpoint = environment.pdf_cotizacion_agente_endpoint;
        const idCotizacion = file.idCotizacion;
        const idAseguradora = file.idAseguradora;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<PDFCotizacionDTO>(`${environment.url_base}/${endpoint}/${idCotizacion}/${idAseguradora}`, httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getCotizacionPDFAgentesSin(file: GetCotizacionDTO): Observable<PDFCotizacionDTO> {
        const endpoint = environment.pdf_cotizacion_agentesin_endpoint;
        const idCotizacion = file.idCotizacion;
        const idAseguradora = file.idAseguradora;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<PDFCotizacionDTO>(`${environment.url_base}/${endpoint}/${idCotizacion}/${idAseguradora}`, httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getCotizacionPDF(file: GetCotizacionDTO): Observable<PDFCotizacionDTO> {
        const endpoint = environment.pdf_cotizacion_endpoint;
        const idCotizacion = file.idCotizacion;
        const idAseguradora = file.idAseguradora;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<PDFCotizacionDTO>(`${environment.url_base}/${endpoint}/${idCotizacion}/${idAseguradora}`, httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    printCotizacionPDF(file: GetCotizacionDTO): Observable<PDFCotizacionDTO> {
        const endpoint = environment.print_cotizacion_endpoint;
        const idCotizacion = file.idCotizacion;
        const idAseguradora = file.idAseguradora;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<PDFCotizacionDTO>(`${environment.url_base}/${endpoint}/${idCotizacion}/${idAseguradora}`, httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    sendPolizaPDF(file: SendPolizaDTO): Observable<SendPolizaRequestDTO> {
        const endpoint = environment.send_email_poliza;
        const idCotizacion = file.idCotizacion;
        const idAseguradora = file.idAseguradora;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<SendPolizaRequestDTO>(`${environment.url_base}/${endpoint}/${idCotizacion}/${idAseguradora}`, httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    sendPolizaPDFWithoutPay(file: SendPolizaDTO): Observable<SendPolizaRequestDTO> {
        const endpoint = environment.send_email_poliza_withoutpay;
        const idCotizacion = file.idCotizacion;
        const idAseguradora = file.idAseguradora;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<SendPolizaRequestDTO>(`${environment.url_base}/${endpoint}/${idCotizacion}/${idAseguradora}`, httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    handleError(error: HttpErrorResponse): Observable<never> {
        return throwError(error);
    }
}
